<template>
  <div class="container-layout-custom justify-center full-height ak-text light-theme-text-default" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row q-mb-xl">
        <div class="text-h4 col-md-9">Submit a Request</div>
      </div>
      <div class="row q-mb-xl">
        <div class="col-6"><q-select label="Type" v-model="msgType" :options="types" popup-content-class="bg-lmsBackground primaryText-text" /></div>
      </div>
      <div class="row q-mb-xl">
        <div class="col-6">
          <q-input v-model="msgBody" filled type="textarea" label="Message" />
        </div>
      </div>
      <div class="row q-mb-xl">
        <div class="col-6">
          <q-btn flat type="reset" color="primary" @click="reset()">Cancel</q-btn>
          <q-btn @click="sendRequest()" :disable="(this.msgType !== null && this.msgBody !== '') ? false : true" color="primary" class="float-right">Send</q-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      types: [
        'Bug', 'Feature'
      ],
      msgType: null,
      msgBody: ''
    }
  },
  methods: {
    reset () {
      this.msgType = null
      this.msgBody = ''
    },
    async sendRequest () {
      this.$q.loading.show()
      try {
        await this.$axios.post(this.$consts.SEND_BUG_OR_FEATURE_REQUEST_URL, { type: this.msgType, message: this.msgBody })
        this.msgBody = ''
        this.msgType = null
        this.$q.loading.hide()
        this.$successMsg('Your request has been sent')
      } catch (e) {
        this.$failureMsg()
      }
    }
  }
}
</script>
